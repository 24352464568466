import React, { createContext } from "react"
import { Helmet } from "react-helmet"
import slickStyles from "slick-carousel/slick/slick.css"

export const LocaleContext = createContext()

export default ({ children, lang = "en-US" }) => (
  <LocaleContext.Provider value={lang.split("-")[0]}>
    <Helmet>
      <html lang={lang.split("-")[0]} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="stylesheet" href="https://use.typekit.net/evz5cao.css" />
      <style dangerouslySetInnerHTML={{ __html: slickStyles }} />
    </Helmet>
    <style global jsx>{`
      * {
        box-sizing: border-box;
      }

      html,
      body {
        padding: 0;
        margin: 0;
        font-weight: 300;
        background-color: #101820;
        font-family: aktiv-grotesk, "helvetica neue", sans-serif;
      }

      .section-white {
        background-color: white;
      }

      .section-dark {
        background-color: #161f29;
      }
    `}</style>
    {children}
  </LocaleContext.Provider>
)
